<template>
  <el-select
    class="w-full"
    :size="size"
    filterable
    v-model="articleId"
    remote
    clearable
    :remote-method="searchArticle"
    :loading="loadingArticle"
    placeholder="Rechercher  article"
    @change="selectArticle()"
    @clear="handleClear()"
  >
    <el-option
      v-for="item in articles"
      :key="item.article_id"
      :label="` ${item.article_name}`"
      :value="item.article_id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { searchArticle } from "@/api/article";
export default {
  name: "ArticleSearch",
  props: {
    /*  data: {
      type: Object,
      require,
    },*/
    size: {
      type: String,
    },
  },
  data() {
    return {
      articleId: "",
      articles: [],
      loadingArticle: false,
    };
  },
  methods: {
    selectArticle() {
      this.$emit("selected", this.articleId);
    },
    async searchArticle(query) {
      if (query.length !== "" && query.length > 2) {
        this.loadingArticle = true;
        await searchArticle({ name: query }).then((res) => {
          this.articles = res.data;
          console.log(this.articles);
          this.loadingArticle = false;
        });
      }
    },
    handleClear() {
      this.$emit("clear-article");
    },
  },
};
</script>

<style lang="scss" scoped></style>
