<template>
  <div class="flex flex-col font-Inter">
    <div class="flex flex-col my-6 bg-white shadow px-6 py-6">
      <div class="filter-container">
        <ArticleSearch
          size="medium"
          style="width: 20%"
          class="filter-item mr-1"
          @selected="handleSelectedArticle"
          @clear-article="handleClearArticle"
        />
        <el-input
          style="width: 10%"
          size="medium"
          class="filter-item mr-1"
          placeholder="Reference"
          v-model="listQuery.reference"
          clearable
          @keydown.native.enter="fetchMovementStock()"
        >
        </el-input>
        <el-select
          size="medium"
          style="width: 15%"
          class="filter-item mr-1"
          placeholder="Type"
          v-model="listQuery.type"
          @clear="clearType()"
          filterable
          clearable
          @change="fetchMovementStock()"
        >
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :value="item.name"
            :label="item.libelle"
          >
          </el-option>
        </el-select>

        <el-select
          size="medium"
          style="width: 15%"
          class="filter-item mr-1"
          v-model="listQuery.warehouseCode"
          placeholder="Entrepôt "
          @clear="clearWarehouse()"
          filterable
          clearable
          @change="fetchMovementStock()"
        >
          <el-option
            v-for="item in warehouses"
            :key="item.id"
            :label="`${item.name}`"
            :value="item.code"
          >
          </el-option>
        </el-select>

        <el-date-picker
          size="medium"
          style="width: 12%"
          class="filter-item mr-1"
          v-model="listQuery.start"
          type="date"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Date debut"
        >
        </el-date-picker>
        <el-date-picker
          size="medium"
          style="width: 12%"
          class="filter-item mr-1"
          v-model="listQuery.end"
          type="date"
          format="dd MMMM yyyy"
          value-format="yyyy-MM-dd"
          placeholder="Date fin"
        >
        </el-date-picker>

        <el-button
          class="filter-item"
          icon="el-icon-search"
          size="medium"
          @click="fetchMovementStock()"
          >Rechercher</el-button
        >
      </div>

      <div class="customer-white">
        <el-tabs v-model="activeName" @tab-click="handleTab">
          <el-tab-pane label="Tout" name="ALL"></el-tab-pane>
          <el-tab-pane label="Entrées" name="IN"></el-tab-pane>
          <el-tab-pane label="Sorties" name="OUT"></el-tab-pane>
        </el-tabs>
        <div class="flex justify-end">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              Exporter<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>En excel</el-dropdown-item>
              <el-dropdown-item>En PDF</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <skeleton-list :row="8" v-if="listLoading" />
        <el-table :data="items" v-else class="mt-3" style="width: 100%">
          <el-table-column prop="date" label="DATE" width="170">
            <template slot-scope="scope">
              <span> {{ scope.row.date }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="type"
            align="left"
            label="TYPE DOCUMENT"
            width="180"
          >
            <template slot-scope="{ row }">
              <span>{{ row.type }}</span>
            </template>
          </el-table-column>
          <el-table-column align="left" label="REFERENCE" width="170">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <span class="text-blue-500 cursor-pointer">{{
                  row.reference
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="left" width="100" label="TYPE">
            <template slot-scope="{ row }">
              <Status :name="row.status" />
            </template>
          </el-table-column>

          <el-table-column align="left" width="160" label="ENTREPÔTS">
            <template slot-scope="{ row }">
              <span>{{ row.warehouse }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="type"
            align="left"
            label="ARTICLE"
            min-width="200"
          >
            <template slot-scope="{ row }">
              <span>{{ row.article_name }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="quantity_in"
            label="QTÉ"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <div>
                {{ row.quantity }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="purchase_price"
            label="COÛT"
            width="160"
            align="right"
          >
            <template slot-scope="{ row }">
              <span>{{ row.purchase_price | moneyFilter }} {{user.currency_code}} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="purchase_price"
            label="PRIX "
            width="160"
            align="right"
          >
            <template slot-scope="{ row }">
              <span>{{ row.sale_price | moneyFilter }} {{user.currency_code}}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="stock_on_hand"
            label="Nouv. quantité"
            align="right"
            width="150"
          >
            <template slot-scope="{ row }">
              <span>{{ row.stock_on_hand }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="fetchMovementStock()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchTypesMovementStock,
  fetchMovementStock,
  getCategories,
  fetchMovementStatistique,
} from "@/api/article";
import Pagination from "@/components/Pagination";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import ArticleSearch from "@/components/ArticleSearch";
import { fetchWarehouse } from "@/api/stock";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "Article",
  components: { Pagination, ArticleSearch, Status, SkeletonList },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      activeName: "ALL",
      categories: [],
      types: [],
      items: [],
      warehouses: [],
      listLoading: false,
      statistique: {
        totalIn: 0,
        totalOut: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        start: undefined,
        end: undefined,
        type: undefined,
        category: undefined,
        articleId: undefined,
        status: undefined,
        reference: undefined,
        sort: "desc",
        warehouseCode: undefined,
        sortDir: "createdAt",
      },
    };
  },

  mounted() {
    this.fetchStatistique();
    this.fetchCategories();
    this.fetchTypes();
    this.fetchWarehouse();
    this.fetchMovementStock();
  },
    computed: {
        ...mapGetters(["user"]),
    },
  methods: {
    handleEdit(row) {
      this.$emit("article-update", row);
    },
    async fetchStatistique() {
      await fetchMovementStatistique()
        .then((res) => {
          this.statistique.totalIn = res.data.total_in;
          this.statistique.totalOut = res.data.total_out;
          console.log(this.statistique);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchCategories() {
      await getCategories()
        .then((res) => {
          this.categories = res.data;

          // console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchTypes() {
      await fetchTypesMovementStock()
        .then((res) => {
          this.types = res.data;
          // console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTypeLibelle(name) {
      const item = this.types.find((v) => v.name === name);

      return item && item.libelle;
    },
    handleClearArticle() {
      this.listQuery.articleId = undefined;
      this.fetchMovementStock();
    },
    async fetchMovementStock() {
      this.listLoading = true;
      await fetchMovementStock(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
            console.log(this.items);
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearCategory() {
      this.listQuery.category = undefined;
    },
    clearWarehouse() {
      this.listQuery.warehouseCode = undefined;
    },
    clearType() {
      this.listQuery.type = undefined;
    },
    handleSelectedArticle(e) {
      this.listQuery.articleId = e;
      this.fetchMovementStock();
    },
    handleTab(obj) {
      const name = obj.name;
      switch (name) {
        case "IN":
          this.listQuery.status = "IN";
          break;

        case "OUT":
          this.listQuery.status = "OUT";
          break;
        default:
          this.listQuery.status = undefined;
      }
      this.fetchMovementStock();
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },
  },
};
</script>

<style></style>
